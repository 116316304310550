<template>
  <div>
    <b-row
      id="idPncpBiddingFilter"
      class="justify-content-center"
    >
      <b-col
        cols="12"
        md="12"
      >
        <b-card
          no-body
        >
          <b-card-body class="p-0">
            <div
              v-show="!isLoaded"
              class="px-2 py-2"
            >
              <div class="text-center my-3">
                <b-spinner
                  variant="info"
                  class="align-middle mb-1"
                />
                <br>
                <strong class="pt-2">Carregando...</strong>
              </div>
            </div>
            <div
              v-show="isLoaded"
            >
              <b-row
                class="p-2"
              >
                <b-col
                  cols="12"
                  md="8"
                  lg="6"
                >
                  <b-form-group
                    label="Buscar"
                    label-for="idSearchBox"
                    class="mb-0"
                  >
                    <b-form-input
                      id="idSearchBox"
                      v-model.lazy="filter.query"
                      placeholder="Buscar"
                      type="text"
                      class="d-inline-block"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                  class="d-flex align-items-center justify-content-start mt-2 mb-1 px-0"
                >
                  <div
                    v-if="filterSaved"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <b-dropdown
                        id="idBtnDropdownFilter"
                        :text="filterOptions.id !== '' ? getFilterName() : 'Meus Filtros'"
                        class="w-100"
                        variant="outline-primary"
                      >
                        <b-dropdown-item
                          v-for="(obj, index) in filterList"
                          v-show="filterList.length > 0"
                          :key="index"
                          @click="selectFilter(index)"
                        >
                          <svg-icon
                            class="mr-1"
                            type="mdi"
                            size="18"
                            :path="(filterOptions.id === obj.id ) ? mdiCircle : mdiCircleOutline"
                          />
                          {{ obj.name }}
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-show="filterList.length === 0"
                        >
                          Não existem filtros cadastrados
                        </b-dropdown-item>
                        <b-dropdown-divider />
                        <b-dropdown-item
                          v-show="filterList.length > 0"
                          @click="clearFilter()"
                        >
                          Sem Filtro
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-start justify-content-start ml-1"
                  >
                    <div>
                      <b-button
                        v-b-tooltip.hover
                        class="btn-icon rounded-circle"
                        variant="primary"
                        size="sm"
                        title="Filtro Avançado"
                        @click="toggleFilter()"
                      >
                        <svg-icon
                          type="mdi"
                          size="20"
                          :path="mdiFilterPlusOutline"
                        />
                      </b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <div
                v-show="isFilterOpen"
                class="filterOptionsForm p-2"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                    class="d-flex align-items-top justify-content-end"
                  >
                    <div class="w-100">
                      <b-row>
                        <b-col
                          cols="12"
                          md="3"
                        >
                          <hiring-modalities-select
                            id="hiringModalities"
                            v-model="filterOptions.hiring_modalities_set"
                            @ready="syncLoad.ready('hiringModalities')"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          md="3"
                        >
                          <organs-select
                            id="organs"
                            v-model="filterOptions.organ"
                            @ready="syncLoad.ready('organs')"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          md="3"
                        >
                          <units-select
                            id="units"
                            v-model="filterOptions.unit"
                            @ready="syncLoad.ready('units')"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          md="3"
                        >
                          <state-select
                            id="state"
                            v-model="filterOptions.state_set"
                            :title="'UFs'"
                            :readonly="false"
                            :required-field="false"
                            @ready="syncLoad.ready('state')"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col
                          cols="12"
                          md="3"
                        >
                          <city-select
                            id="city"
                            v-model="filterOptions.city"
                            @ready="syncLoad.ready('city')"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          md="3"
                        >
                          <spheres-select
                            id="spheres"
                            v-model="filterOptions.spheres_set"
                            @ready="syncLoad.ready('spheres')"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          md="3"
                        >
                          <powers-select
                            id="powers"
                            v-model="filterOptions.powers_set"
                            @ready="syncLoad.ready('powers')"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          md="3"
                        >
                          <type-calling-instrument-select
                            id="typeCallingInstrument"
                            v-model="filterOptions.type_calling_instrument_set"
                            @ready="syncLoad.ready('typeCallingInstrument')"
                          />
                        </b-col>
                      </b-row>
                    </div>
                    <div
                      v-if="filterOptions.id !== ''"
                      class="ml-1"
                    >
                      <b-button
                        v-b-tooltip.hover
                        class="btn-icon rounded-circle"
                        variant="flat-info"
                        title="Remover Filtro"
                        @click="confirmDeleteFilter()"
                      >
                        <svg-icon
                          type="mdi"
                          size="18"
                          :path="mdiTrashCanOutline"
                        />
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    class="d-flex justify-content-end buttonsSaveCancel"
                  >
                    <b-button
                      class="filterBtn"
                      variant="primary"
                      @click="saveFilter()"
                    >
                      Salvar
                    </b-button>
                    <b-button
                      class="filterBtn ml-1"
                      variant="outline-primary"
                      @click="cancelFilter()"
                    >
                      Cancelar
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <validation-observer
      v-slot="{ handleSubmit, invalid }"
      ref="refFormObserver"
    >
      <b-modal
        id="idModalFilter"
        ref="refModalFilter"
        no-close-on-backdrop
        no-close-on-esc
        centered
        cancel-variant="outline-primary"
        :ok-disabled="invalid"
        @ok="handleSubmit(submit)"
      >
        <template #modal-title>
          Cadastro de Filtro
        </template>
        <template #modal-cancel>
          Cancelar
        </template>
        <template #modal-ok>
          Salvar
        </template>
        <b-form
          class="mt-1"
        >
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="name"
                :rules="{
                  required: true,
                }"
              >
                <b-form-group
                  label="Nome"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="filterOptions.name"
                    name="name"
                    placeholder="Nome"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BSpinner,
  BForm,
  BFormInput,
  BFormGroup,
  BFormInvalidFeedback,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  VBTooltip,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  mdiChevronRight,
  mdiFilterPlusOutline,
  mdiTrashCanOutline,
  mdiCircle,
  mdiCircleOutline,
} from '@mdi/js'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import SyncLoad from '@/components/utils/syncLoad'
import HiringModalitiesSelect from '@/components/pncp/HiringModalitiesSelect.vue'
import StateSelect from '@/components/address/StateSelect.vue'
import CitySelect from '@/components/pncp/CitySelect.vue'
import SpheresSelect from '@/components/pncp/SpheresSelect.vue'
import PowersSelect from '@/components/pncp/PowersSelect.vue'
import TypeCallingInstrumentSelect from '@/components/pncp/TypeCallingInstrumentSelect.vue'
import UnitsSelect from '@/components/pncp/UnitsSelect.vue'
import OrgansSelect from '@/components/pncp/OrgansSelect.vue'
import MetaData from '@/components/utils/metaData'
import syslic from '@/syslic'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BSpinner,
    BForm,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ValidationProvider,
    ValidationObserver,
    SvgIcon,
    HiringModalitiesSelect,
    StateSelect,
    CitySelect,
    SpheresSelect,
    PowersSelect,
    TypeCallingInstrumentSelect,
    UnitsSelect,
    OrgansSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  emits: ['ready'],
  props: {
    filterSaved: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    const filter = {
      query: '',
      docType: 'edital',
      ordering: '-data',
      status: 'recebendo_proposta',
      modalidades: '',
      ufs: '',
      esferas: '',
      poderes: '',
      tipos: '',
      orgaos: '',
      unidades: '',
      municipios: '',
    }

    const isFilterOpen = false
    const filterList = []

    const isLoaded = false
    const syncLoad = new SyncLoad()

    syncLoad.addTrigger('hiringModalities')
    syncLoad.addTrigger('state')
    syncLoad.addTrigger('spheres')
    syncLoad.addTrigger('powers')
    syncLoad.addTrigger('typeCallingInstrument')
    syncLoad.addTrigger('units')
    syncLoad.addTrigger('city')
    syncLoad.addTrigger('organs')
    syncLoad.addTrigger('filters')

    syncLoad.onReady(() => {
      this.isLoading = false
      this.$emit('ready', true)
    })

    const filterEmpty = {
      id: '',
      name: '',
      page_size: this.perPage,
      search: '',
      hiring_modalities_set: '',
      organ: '',
      unit: '',
      state_set: '',
      city: '',
      spheres_set: '',
      powers_set: '',
      type_calling_instrument_set: '',
    }

    const filterOptions = JSON.parse(JSON.stringify(filterEmpty))

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.value)

    const meta = new MetaData('PncpBiddingFilter')

    return {
      isLoaded,
      isFilterOpen,
      filterList,
      filter,
      syncLoad,
      filterEmpty,
      filterOptions,
      refFormObserver,
      getValidationState,
      meta,
    }
  },
  watch: {
    filter: {
      handler: function updateFilter() {
        this.$emit('filter-change', this.filter)
      },
      deep: true,
    },
    'filterOptions.hiring_modalities_set': function updateModalitie(val) {
      if (val) {
        this.filterOptions.hiring_modalities = val.id
        this.filter.modalidades = val.codigo
      } else {
        this.filterOptions.hiring_modalities = ''
        this.filter.modalidades = ''
      }
    },
    'filterOptions.state_set': function updateState(val) {
      if (val) {
        this.filterOptions.state = val.id
        this.filter.ufs = val.code
      } else {
        this.filterOptions.state = ''
        this.filter.ufs = ''
      }
    },
    'filterOptions.spheres_set': function updateSpheres(val) {
      if (val) {
        this.filterOptions.spheres = val.id
        this.filter.esferas = val.codigo
      } else {
        this.filterOptions.spheres = ''
        this.filter.esferas = ''
      }
    },
    'filterOptions.powers_set': function updatePowers(val) {
      if (val) {
        this.filterOptions.powers = val.id
        this.filter.poderes = val.codigo
      } else {
        this.filterOptions.powers = ''
        this.filter.poderes = ''
      }
    },
    'filterOptions.type_calling_instrument_set': function updateTypeCalling(val) {
      if (val) {
        this.filterOptions.type_calling_instrument = val.id
        this.filter.tipos = val.codigo
      } else {
        this.filterOptions.type_calling_instrument = ''
        this.filter.tipos = ''
      }
    },
    'filterOptions.organ': function updateOrgan(val) {
      if (val) {
        this.filter.orgaos = val.id
      } else {
        this.filter.orgaos = ''
      }
    },
    'filterOptions.unit': function updateUnits(val) {
      if (val) {
        this.filter.unidades = val.id
      } else {
        this.filter.unidades = ''
      }
    },
    'filterOptions.city': function updateCity(val) {
      if (val) {
        this.filter.municipios = val.id
      } else {
        this.filter.municipios = ''
      }
    },
  },
  created() {
    this.isLoaded = true
    this.filterOptions = this.meta.bind(this.filterOptions, 'filterOptions')

    this.loadFilters()
  },
  methods: {
    loadFilters() {
      syslic
        .pncp
        .filter
        .fetchFilters()
        .then(response => {
          if (response.data.results.length > 0) {
            this.filterList = response.data.results
          }
          this.syncLoad.ready('filters')
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados.',
              text: 'Não foi possível ler os dados do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.syncLoad.ready('filters')
        })
    },
    toggleFilter() {
      this.isFilterOpen = !this.isFilterOpen
    },
    selectFilter(index) {
      const { page_size, search } = this.filterOptions

      this.filterOptions = JSON.parse(JSON.stringify(this.filterList[index]))

      /* eslint camelcase: ["off"] */
      this.filterOptions.page_size = page_size
      this.filterOptions.search = search
    },
    clearFilter() {
      const { page_size, search } = this.filterOptions
      this.filterOptions = JSON.parse(JSON.stringify(this.filterEmpty))

      /* eslint camelcase: ["off"] */
      this.filterOptions.page_size = page_size
      this.filterOptions.search = search

      this.isFilterOpen = false
    },
    deleteFilter() {
      syslic
        .pncp
        .filter
        .deleteFilter(this.filterOptions.id)
        .then(() => {
          this.filterList.forEach((obj, index) => {
            if (obj.id === this.filterOptions.id) {
              this.filterList.splice(index, 1)
            }
          })

          this.clearFilter()

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Filtro removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          const textError = 'Não foi possível remover o filtro, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover filtro.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    confirmDeleteFilter() {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você quer remover o filtro.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteFilter()
          }
        })
    },
    getFilterName() {
      const stop = 25
      const text = this.filterOptions.name
      return text.slice(0, stop) + (stop < text.length ? '...' : '')
    },
    saveFilter() {
      this.$refs.refModalFilter.show()
    },
    cancelFilter() {
      this.filterList.forEach((element, index) => {
        if (element.id === this.filterOptions.id) {
          this.selectFilter(index)
        }
      })
      this.toggleFilter()
      this.clearFilter()
    },
    getFilter(val) {
      const {
        id,
        name,
        page_size,
        search,
        hiring_modalities_set,
        organ,
        unit,
        state_set,
        city,
        spheres_set,
        powers_set,
        type_calling_instrument_set,
      } = val

      const filter = {
        id,
        name,
        page_size,
        search,
        hiring_modalities: hiring_modalities_set?.id || '',
        state: state_set?.id || '',
        spheres: spheres_set?.id || '',
        powers: powers_set?.id || '',
        type_calling_instrument: type_calling_instrument_set?.id || '',
        organ,
        unit,
        city,
      }

      return filter
    },
    submit() {
      const filter = this.getFilter(this.filterOptions)
      if (filter.id === '') {
        this.createFilter(filter)
      } else {
        this.updateFilter(filter)
      }
    },
    createFilter(filter) {
      syslic
        .pncp
        .filter
        .addFilter(filter)
        .then(response => {
          this.filterList.push(response.data)
          this.selectFilter((this.filterList.length - 1))

          if (this.filterList[0].id === '') {
            this.filterList.splice(0, 1)
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Filtro adicionado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          let textError = 'Não foi possível criar o filtro, por favor entre em contato com o administrador do sistema.'

          if (err.data.name !== undefined) {
            if (err.data.name.includes('order filter with this name already exists.')) {
              textError = 'Não foi possível criar este filtro pois já existe um filtro cadastrado com o mesmo nome.'
            }
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao criar filtro.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    updateFilter(filter) {
      syslic
        .pncp
        .filter
        .updateFilter(filter.id, filter)
        .then(response => {
          this.filterList.forEach((element, index) => {
            if (element.id === response.data.id) {
              this.filterList[index] = response.data
            }
          })

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Filtro atualizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          let textError = 'Não foi possível atualizar o filtro, por favor entre em contato com o administrador do sistema.'

          if (err.data.name !== undefined) {
            if (err.data.name.includes('order filter with this name already exists.')) {
              textError = 'Não foi possível atualizar este filtro pois já existe um filtro cadastrado com o mesmo nome.'
            }
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar filtro.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiChevronRight,
      mdiFilterPlusOutline,
      mdiTrashCanOutline,
      mdiCircle,
      mdiCircleOutline,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#idPncpBiddingFilter {
  .filterOptionsForm {
    background-color: #FAFAFC;
    border-top: 1px solid #d8d6de;
  }
}

#state .v-select {
  background-color: #fff;
}

#idBtnDropdownFilter {
  min-width: 20rem;
}

#idModalFilter .modal-footer {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: end;

  .btn{
    min-width: 107px;
  }
}
</style>
