<template>
  <div>
    <b-row
      id="idPncpBiddingList"
      class="justify-content-center"
    >
      <b-col
        cols="12"
        md="11"
      >
        <pncp-bidding-filter
          @filter-change="updateFilter"
        />

        <pncp-bidding-cards
          :filter="filter"
          :current-page="currentPage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

import PncpBiddingFilter from '@/views/pncp/PncpBiddingFilter.vue'
import PncpBiddingCards from '@/views/pncp/PncpBiddingCards.vue'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    PncpBiddingFilter,
    PncpBiddingCards,
  },
  data() {
    const filter = {
      query: '',
      docType: 'edital',
      ordering: '-data',
      status: 'recebendo_proposta',
      modalidades: '',
      ufs: '',
      esferas: '',
      poderes: '',
      tipos: '',
      orgaos: '',
      unidades: '',
      municipios: '',
    }

    const currentPage = router.currentRoute.params.page ? router.currentRoute.params.page : 1

    return {
      filter,
      currentPage,
    }
  },
  methods: {
    updateFilter(obj) {
      this.filter = obj
    },
  },
  setup() {
    return {
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#idPncpBiddingList {
  .clsItemLabel {
    display: block;
    color: $primary;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }

  .clsItemContent {
    display: block;
    font-size: 1.125rem;
    font-weight: 400;
  }

  .clsItemOpen {
    margin-top: calc(50% + (34px / 2));
    position: relative;
  }

  .clsItemEven {
    background-color: #F6F6F9;
    border-top: 1px solid #ebe9f1;
  }

  .clsItemOdd {
    background-color: #FFFFFF;
    border-top: 1px solid #ebe9f1;
  }
}

</style>
