<template>
  <div>
    <validation-provider
      #default="{ errors }"
      name="units"
      :rules="requiredField ? 'required' : ''"
    >
      <b-form-group
        label="Unidades"
        label-for="units"
      >
        <v-select
          id="units"
          v-model="units"
          :class="errors.length > 0 ? 'error-select' : ''"
          :filter="fuseSearch"
          label="nome"
          :options="dataOptions"
          :placeholder="'Unidades'"
          :disabled="readonly"
          :clearable="!readonly"
          @open="onCreate()"
        >
          <template #option="{ nome }">
            <span>{{ nome }}</span>
          </template>

          <template #selected-option="{ nome }">
            <span>{{ nome | truncate(truncateField, '...') }}</span>
          </template>

          <template #no-options>
            Não há opções de unidade.
          </template>
        </v-select>
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </div>
</template>
<script>
import {
  BFormGroup,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  required,
} from '@validations'

import vSelect from 'vue-select'
import Fuse from 'fuse.js'
import truncate from '@/components/utils/filters/truncate'
import syslic from '@/syslic'

export default {
  components: {
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  filters: {
    truncate,
  },
  model: {
    prop: 'unitsValue',
    event: 'unitsChange',
  },
  props: {
    unitsValue: {
      type: [Object, String],
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
    truncateField: {
      type: Number,
      required: false,
      default: 17,
    },
  },
  data() {
    const dataOptions = []
    const newUnitsValue = this.unitsValue

    return {
      dataOptions,
      newUnitsValue,
      required,
    }
  },
  computed: {
    units: {
      get() {
        return this.newUnitsValue
      },
      set(value) {
        this.newUnitsValue = value
        this.$emit('unitsChange', this.newUnitsValue)
      },
    },
  },
  watch: {
    unitsValue(newVal) {
      this.newUnitsValue = newVal
    },
  },
  created() {
    this.onCreate()
  },
  methods: {
    onCreate() {
      syslic
        .pncp
        .units
        .fetchUnits()
        .then(response => {
          this.dataOptions = response
          this.$emit('ready', true)
        })
        .catch(() => {
          this.$emit('ready', true)
        })
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['nome'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
.v-select {
  background-color: #fff;
}
</style>
