<template>
  <div>
    <b-row
      id="idPncpBiddingCards"
      class="justify-content-center"
    >
      <b-col
        cols="12"
        md="12"
      >
        <b-card
          no-body
        >
          <b-card-body class="p-0">
            <div
              v-show="!isLoaded"
              class="px-2 py-2"
            >
              <div class="text-center my-3">
                <b-spinner
                  variant="info"
                  class="align-middle mb-1"
                />
                <br>
                <strong class="pt-2">Carregando...</strong>
              </div>
            </div>
            <div
              v-show="isLoaded"
            >
              <div
                v-for="(item, index) in data"
                :key="index"
                :class="(index % 2) ? 'clsItemEven' : 'clsItemOdd'"
              >
                <div class="biddingImported">
                  <svg-icon
                    v-if="item.isImported"
                    v-b-tooltip.hover
                    type="mdi"
                    size="28"
                    :path="mdiMarkerCheck"
                    title="Licitação Importada"
                  />
                </div>
                <div class="ml-2 px-2 py-2 d-flex">
                  <div class="d-inline w-100">
                    <b-row>
                      <b-col
                        cols="12"
                        md="3"
                      >
                        <span class="clsItemLabel">ID contratação PNCP:</span>
                        <span class="clsItemContent">{{ item.numero_controle_pncp }}</span>
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                      >
                        <span class="clsItemLabel">Modalidade:</span>
                        <span class="clsItemContent">{{ item.modalidade_licitacao_nome }}</span>
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                      >
                        <span class="clsItemLabel">Ultima Atualização:</span>
                        <span class="clsItemContent">{{ moment(item.data_atualizacao_pncp).format('DD/MM/YYYY') }}</span>
                      </b-col>
                      <b-col
                        cols="12"
                        md="3"
                      >
                        <span class="clsItemLabel">Orgão:</span>
                        <span class="clsItemContent">{{ item.orgao_nome }}</span>
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                      >
                        <span class="clsItemLabel">Local:</span>
                        <span class="clsItemContent">{{ item.municipio_nome }}/{{ item.uf }}</span>
                      </b-col>
                    </b-row>
                    <b-row
                      class="mt-1"
                    >
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <span class="clsItemLabel">Objeto:</span>
                        <span class="clsItemContent">{{ item.description | truncate(320, '...') }}</span>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="d-inline">
                    <b-button
                      class="btn-icon rounded-circle clsItemOpen"
                      variant="flat-primary"
                      size="md"
                      @click="openItem(item)"
                    >
                      <svg-icon
                        type="mdi"
                        :path="mdiChevronRight"
                      />
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="isLoaded">
      <b-col
        cols="12"
        class="d-flex align-items-center justify-content-center"
      >
        <b-pagination
          v-model="page"
          :total-rows="totalItems"
          :per-page="pageSize"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BPagination,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  mdiChevronRight,
  mdiMarkerCheck,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import router from '@/router'
import syslic from '@/syslic'
import truncate from '@/components/utils/filters/truncate'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BPagination,
    BSpinner,
    SvgIcon,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    truncate,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    currentPage: {
      type: [Number, String],
      required: false,
      default: 1,
    },
  },
  emits: ['ready'],
  data() {
    const page = this.currentPage
    const pageSize = 10
    const totalItems = 0

    const isLoaded = false
    const data = []
    const fetchId = ''

    const biddingImported = []

    return {
      page,
      pageSize,
      totalItems,
      isLoaded,
      data,
      fetchId,
      biddingImported,
    }
  },
  watch: {
    page: function updatePage() {
      this.fetchData()
    },
    filter: {
      handler: function updateFilter() {
        this.fetchData()
      },
      deep: true,
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.isLoaded = false
      const fetchPending = Math.random()
      this.fetchId = fetchPending

      syslic
        .pncp
        .fetchBiddings(
          this.page,
          this.pageSize,
          this.filter.query,
          this.filter.docType,
          this.filter.ordering,
          this.filter.status,
          this.filter.modalidades,
          this.filter.ufs,
          this.filter.esferas,
          this.filter.poderes,
          this.filter.tipos,
          this.filter.orgaos,
          this.filter.unidades,
          this.filter.municipios,
        )
        .then(response => {
          let promise = new Promise(resolve => {
            resolve(false)
          })
          if (fetchPending === this.fetchId) {
            this.data = response.data.items
            this.totalItems = response.data.total
            promise = this.fetchBiddingsImported()
          }
          return promise
        })
        .then(response => {
          if (fetchPending === this.fetchId) {
            if (response) {
              this.$emit('ready', true)
              this.isLoaded = true
            }
          }
        })
        .catch(() => {
          if (fetchPending === this.fetchId) {
            this.isLoaded = true
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao buscar licitações do Portal Nacional de Contratações publicas.',
                text: '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
            this.$emit('ready', true)
          }
        })
    },
    fetchBiddingsImported() {
      const promiseList = []
      this.data.forEach((element, index) => {
        const params = {
          cnpj: element.orgao_cnpj,
          year: element.ano,
          seq: element.numero_sequencial,
        }
        const promise = new Promise(resolve => {
          syslic
            .pncp
            .fetchBiddingsImportedSimple(params)
            .then(response => {
              this.data[index].isImported = false
              if (response.data.count > 0) {
                this.data[index].isImported = true
              }
              resolve()
            })
        })
        promiseList.push(promise)
      })
      const promise = new Promise(resolve => {
        Promise.allSettled(promiseList)
          .then(() => {
            resolve(true)
          })
          .catch(() => {
            resolve(false)
          })
      })
      return promise
    },
    openItem(item) {
      router.push({
        name: 'bidding-search-view',
        params: {
          cnpj: item.orgao_cnpj,
          year: item.ano,
          seq: item.numero_sequencial,
          page: this.page,
        },
      })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      moment,
      mdiChevronRight,
      mdiMarkerCheck,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#idPncpBiddingCards {
  .clsItemLabel {
    display: block;
    color: $primary;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }

  .clsItemContent {
    display: block;
    font-size: 1.125rem;
    font-weight: 400;
  }

  .clsItemOpen {
    margin-top: calc(50% + (34px / 2));
    position: relative;
  }

  .clsItemEven {
    background-color: #F6F6F9;
    border-top: 1px solid #ebe9f1;
  }

  .clsItemOdd {
    background-color: #FFFFFF;
    border-top: 1px solid #ebe9f1;
  }
}

.biddingImported {
  color: $primary;

  position: absolute;
  margin-left: 6px;
}
</style>
